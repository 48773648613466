@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .prose {
        @apply max-w-prose mx-auto px-4 sm:px-6 lg:px-8 text-[#1B222A] dark:text-gray-200;
    }

    /* Enhanced Heading styles with better spacing and responsive sizes */
    .prose h1 {
        @apply text-2xl sm:text-3xl lg:text-4xl font-bold mt-2 mb-2 text-[#DB4F40] dark:text-[#DB4F40]/80 leading-tight;
    }

    .prose h2 {
        @apply text-xl sm:text-2xl lg:text-3xl font-semibold mt-2 mb-2 text-[#DB4F40] dark:text-[#DB4F40]/80 leading-tight;
    }

    .prose h3, .prose h4, .prose h5 {
        @apply text-lg sm:text-xl lg:text-xl font-medium mt-0 mb-0 text-[#DB4F40]/80 dark:text-gray-100 leading-snug;
    }


    /* Improved paragraph spacing and line height */
    .prose p {
        @apply text-base sm:text-lg mb-6 leading-relaxed tracking-normal;
    }

    /* Enhanced list spacing */
    .prose ul,
    .prose ol {
        @apply mb-6 pl-6 space-y-3;
    }

    .prose ul {
        @apply list-disc;
    }

    .prose ol {
        @apply list-decimal;
    }

    .prose li {
        @apply mb-2 leading-relaxed text-base sm:text-lg;
    }

    /* Improved link styles */
    .prose a {
        @apply text-[#DB4F40] dark:text-[#DB4F40]/80 hover:text-[#C64537] dark:hover:text-[#DB4F40] transition-colors duration-300;
        text-decoration-line: underline;
        text-decoration-color: currentColor;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;  /* Increased from 2px */
      }

    /* Enhanced button styles */
    .prose div.course-ctas a {
        @apply block px-6 py-3 text-base sm:text-lg text-[#DB4F40] dark:text-gray-200 hover:bg-[#EEA0ED]/10 dark:hover:bg-gray-700 rounded-lg transition-all duration-300;
    }

    /* Improved tag styles */
    .prose [class*="class_type"],
    .prose [class*="contacts"] {
        @apply font-bold text-base sm:text-lg;
    }

    .prose [class*="class_value"] {
        @apply ml-8 text-base sm:text-lg;
    }

    .prose div {
        margin-bottom: 1.5rem;
        }

    /* Enhanced content overview styles */
    .prose p.content-overview__item__text {
        @apply block px-6 py-3 text-base sm:text-lg text-[#1B222A] dark:text-gray-200 hover:bg-[#EEA0ED]/10 dark:hover:bg-gray-700 rounded-lg transition-all duration-300;
    }

    /* Improved blockquote and code styles */
    .prose blockquote {
        @apply border-l-4 border-[#DB4F40] pl-6 italic my-8 text-[#1B222A]/80 text-lg sm:text-xl py-2;
    }

    .prose code {
        @apply bg-[#EEA0ED]/10 dark:bg-gray-800 rounded-md px-2 py-1 font-family-mono text-sm sm:text-base;
    }

    .prose pre {
        @apply bg-[#EEA0ED]/10 dark:bg-gray-800 rounded-lg p-6 overflow-x-auto mb-8 shadow-sm;
    }

    .prose pre code {
        @apply font-family-mono text-sm sm:text-base leading-relaxed;
    }

    /* Enhanced image styles */
    .prose img {
        @apply rounded-xl shadow-lg my-8 max-w-full h-auto;
    }

}


/* React Select Overrides should be OUTSIDE @layer blocks */
.react-select__menu {
    border-radius: 12px;
    margin-top: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.react-select__input input {
    color: #333 !important;
}

.react-select__placeholder {
    color: #999 !important;
}

@layer utilities {
    .font-family-mono {
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    }
}

@layer components {
    /* Handle consecutive <br> tags */
    br + br {
        display: none; /* Hide the second <br> */
    }
}

@layer components {
    .opportunity-description-container {
        @apply bg-white p-8 rounded-xl shadow-lg;
    }

    .opportunity-heading-1 {
        @apply text-3xl sm:text-4xl font-bold text-[#DB4F40] mb-6 border-b-2 border-[#EEA0ED] pb-3;
    }

    .opportunity-heading-2 {
        @apply text-2xl sm:text-3xl font-semibold text-[#1B222A] mb-4 mt-8;
    }

    .opportunity-heading-3 {
        @apply text-xl sm:text-2xl font-medium text-[#1B222A]/80 mb-3 mt-6;
    }

    .opportunity-paragraph {
        @apply text-base sm:text-lg text-[#1B222A] leading-relaxed mb-6;
    }

    .opportunity-responsibilities,
    .opportunity-requirements {
        @apply text-lg sm:text-xl text-[#1B222A] font-semibold mb-4 p-4 rounded-lg;
    }

    .opportunity-responsibilities {
        @apply bg-[#EEA0ED]/10;
    }

    .opportunity-requirements {
        @apply bg-[#EEA0ED]/20;
    }

    .opportunity-list,
    .opportunity-ordered-list {
        @apply pl-6 mb-6 text-[#1B222A] space-y-3;
    }

    .opportunity-list {
        @apply list-disc;
    }

    .opportunity-ordered-list {
        @apply list-decimal;
    }

    .opportunity-list-item {
        @apply leading-relaxed text-base sm:text-lg;
    }
}